import { lazy, StrictMode, createContext, useContext, useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Landing, StorePage, ProductPage, PortfolioPage, ContactPage, CheckoutPage, NavBar }from './App';
import { createBrowserRouter, redirect } from "react-router-dom";
import { RouterProvider } from "react-router-dom";
import Client from 'shopify-buy';
import reportWebVitals from './reportWebVitals';

export const ShopifyContext = createContext({});


const cache = {};

function importAll(r) {
    r.keys().forEach((key) => (cache[key] = r(key)));
}
importAll(require.context("./images", false, /\.(png|JPe?G|svg|jpe?g)$/));
const images = Object.entries(cache).map(module => module[1]);

function App() {
  const [clientState, setClient] = useState({});
  const [productsState, setProducts] = useState([]);
  const [checkoutState, setCheckout] = useState({});
  
  useEffect(() => {
    const client = Client.buildClient({
      storefrontAccessToken: process.env.REACT_APP_STOREFRONT_API_KEY,
      domain: '368338.myshopify.com/'
    });
    setClient(client);
    client.product.fetchAll().then((products) => {
      setProducts(products);
      console.log(products)
    });

    client.checkout.create().then((checkout) => {
      setCheckout(checkout);
    });
  }, []);

  const router = createBrowserRouter(
    [
      {
      path: "/",
      element: <Landing />,
      },
      {
        path: "/store",
        element: <StorePage />
      },
      {
        path: "/portfolio", 
        element: <PortfolioPage images={images}/>
      },
      {
        path: "/contact", 
        element: <ContactPage />
      },
      {
        path: "/product/:productHandle",
        element: <ProductPage />
      },
      {
        path: "/checkout",
        element: <CheckoutPage />
      },
      {
        path: "*",
        loader: async () => {
          return redirect("/")
        }
      }
    ]
  );

  return (
    <ShopifyContext.Provider value={{clientState, setClient, productsState, setProducts, checkoutState, setCheckout}}>
      <RouterProvider router={router} />
    </ShopifyContext.Provider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <App />  
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import './App.css';
import img1 from './images/DJI_0888-min.JPG';
import { Fragment, useContext, useEffect, useState } from 'react';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { ShopifyContext } from "./index.js"


export function NavBar(){
  return(
    <nav className="navBar">
      <div className='preNav'>
        <div className='preNavElement'>Harrison     Jenkins     Photography</div>
      </div>
      <div className="navElementContainer">
        <div className='navElement'><NavLink to={'/'}>HOME</NavLink></div>
        <div className='navElement'><NavLink to={'/store'}>STORE</NavLink></div>
        <div className='navElement'><a href="https://www.instagram.com/_harrisonjenkins/" target="_blank">INSTAGRAM</a></div>
      </div>
    </nav>
  )
}

function Rainbow(){
  return(
    <div id="rainbow">
      <div className='rainbowElement' style={{backgroundColor: "#437FFF"}}></div>
      <div className='rainbowElement' style={{backgroundColor: "#C785B1"}}></div>
      <div className='rainbowElement' style={{backgroundColor: "#ECB92D"}}></div>
      <div className='rainbowElement' style={{backgroundColor: "#16828E"}}></div>
      <div className='rainbowElement' style={{backgroundColor: "#DC2929"}}></div>
    </div>
  )
}

function Barcode(){
  return(
    <div id="barcode">
     CODE
    </div>
  )
}

export function Landing(){
  const fadeProps = useFade(true)[2];

  return (
    <div id='landingBase'>
      <NavBar />
      <div id="landingContainer" {...fadeProps}>
        <Rainbow />
        <Barcode />
        <div id="landingTile">
          <div id="headline">HARRISON</div>
          <img id="landingImage" src={img1} alt="Logo"/>
        </div>
      </div>
    </div>
  )
}

function ProductListingItem({ product }){
  return(
    <NavLink to={`/product/${product.handle}`}>
      <div className="productBuy" key={product.id}>
        <div className="productTitle">{product.title}</div>
        <img className="productDisplay" src={product.images[0].src} alt="Product"/>
      </div>
    </NavLink>
  )
}

function ProductCheckoutItem({ lineItem }){
  const { clientState, checkoutState, setCheckout} = useContext(ShopifyContext)

  function removeFromCart(){
    console.log(lineItem);
    clientState.checkout.removeLineItems(checkoutState.id, [lineItem.id]).then((checkout) => {
      setCheckout(checkout);
    });
    return;
  }
  
  return(
    <div className='checkoutItem'>
      <div className='checkoutDescription'>{lineItem.title} {lineItem.variant.title !== 'Default Title' && lineItem.variant.title}</div>
      <div className='checkoutImageContainer'><img className='checkoutImage' src={lineItem.variant.image.src} alt="Product" /></div>
      <div className='checkoutCount'>×{lineItem.quantity}</div>
      <div className='checkoutCost'>{(lineItem.variant.price.amount * lineItem.quantity).toFixed(2)} {lineItem.variant.price.currencyCode}</div>
      <div className='checkoutRemoval'><button  className='removeButton' onClick={removeFromCart}>×</button></div>
    </div>
  )
}

function CheckoutButton(){
  const navigate = useNavigate();
  function openCheckout(){
      navigate('/checkout');
  }

  return(
    <div id='checkoutLinkContainer'>
      <button id='proceedButton' className='awesomeButton' onClick={openCheckout}>Proceed to Checkout</button>
    </div>
  )
}

export function StorePage(){
  const { productsState, checkoutState } = useContext(ShopifyContext)
  const fadeProps = useFade(true)[2];
  return(
    <div>
      <NavBar />
      <div id="storeContainer" {...fadeProps}>
        {productsState.map((product) => (
          <ProductListingItem key={product.id} product={product} />
        ))}
      </div>
      {checkoutState.lineItems?.length > 0 && <CheckoutButton />}
    </div>
  )
}

function PortfolioImage({ image, clickHandler}){
  const fadeProps = useFade(true)[2];
  const handler = clickHandler(image);
  return(
    <Fragment>
      <img src={image} onClick={handler} {...fadeProps} className='portfolioImage' alt='Portfolio' />
    </Fragment>
  )
}

function PortfolioOverlay({ image, clearImage }){
  const fadeProps = useFade(true)[2];

  return (
    <Fragment>
    {image !== "" && 
      <div className='portfolioOverlay'>
        <button className='exitOverlayButton' onClick={clearImage}>×</button>
        <div className='portfolioOverlayImageContainer'> 
        <img src={image} {...fadeProps} className='portfolioEnlargedImage'  alt='Portfolio'/>
        </div>
      </div>
    }
    </Fragment>
  )
}

export function PortfolioPage({ images }){
  const [selectedImage, setSelectedImage] = useState("");

  function attachedClickHandler(image){
    function handleClick(){
      setSelectedImage(image);
    }
    return handleClick;
  }

  function clearImage(){
    setSelectedImage("");
  }

  return(
    <Fragment>
      <PortfolioOverlay image={selectedImage} clearImage={clearImage}/>
      <NavBar />
      <div className='portfolioContainer'>
        {images.map((img, index) => (
          <PortfolioImage  key={index} image={img} clickHandler={attachedClickHandler} clearImage={clearImage} />
        ))}
      </div>
    </Fragment>
  )
}

export function ContactPage(){
  return(
    <div>
      <NavBar />
      This is the ContactPage
    </div>
  )
}

export function CheckoutPage(){
  const { checkoutState } = useContext(ShopifyContext);

  function openCheckout(){
    console.log(checkoutState);
    window.open(checkoutState.webUrl);
  }

  return(
    <div>
      <NavBar />
      {checkoutState.lineItems?.length === 0 && <div className='errorPage'>There are no checkout items</div>}
      {checkoutState.lineItems?.length !== 0 &&
      <Fragment>
      <div className='checkoutItemContainer'>
        {checkoutState.lineItems?.map((checkoutItem) => (
          <ProductCheckoutItem key={checkoutItem.id} lineItem={checkoutItem}/>
        ))}

      </div>
      <div id='checkoutLinkContainer'>
      <div className='checkoutTotalPrice'>
        TOTAL:{"   "}
        {checkoutState.lineItems?.reduce((accumulator, currentValue) => {
          return accumulator + (currentValue.variant.price.amount * currentValue.quantity);
        }, 0).toFixed(2)}
        {" "}{checkoutState?.lineItems !== undefined ? checkoutState.lineItems[0].variant.price.currencyCode : ""}
        </div>
      {(checkoutState?.lineItems !== undefined && checkoutState?.lineItems?.length > 0) && <button id='proceedButton' className='awesomeButton' onClick={openCheckout}>Checkout</button>}
      </div>
      </Fragment>
      }
    </div>
    
  )
}

export function ProductPage(){
  const fadeProps = useFade(true)[2];
  const { clientState, productsState, checkoutState, setCheckout} = useContext(ShopifyContext)
  const { productHandle } = useParams();
  const [prod, setProd] = useState({});
  const navigate = useNavigate();
  const [chosenVariant, setVariant] = useState(0);

  useEffect(() => {
    let chosenProduct = productsState.filter((product) => product.handle === productHandle);
    if (chosenProduct.length > 0){
      setProd(chosenProduct[0]);
    }
  }, [productsState, productHandle]);
  
  function addToCart(){
    let lineItemsToAdd = [
      {
        variantId: prod.variants[chosenVariant].id,
        quantity: 1,
      }
    ];

    clientState.checkout.addLineItems(checkoutState.id, lineItemsToAdd).then((checkout) => {
      setCheckout(checkout);
      navigate('/store');
    });
    return;
  }

  function selectVariant(variantIndex){
    setVariant(variantIndex);
  }

  return(
    <div>
      <NavBar />
      <div className="productPageContainer" {...fadeProps} >
        {!prod.handle && <div className='errorPage'>This product does not exist</div>}
        {prod.handle && 
          <div className='productPageContent'>
            <div className='productPageLeftColumn'>
              <div className="productPageTitle">{prod.title}</div>
              <div><img className="productPageDisplay" src={prod.images[0].src} alt='Product'/></div>
            </div>
            <div className='productPageRightColumn'>
              <div className='productDescription' dangerouslySetInnerHTML={{__html: prod.descriptionHtml}}></div>
              <div className='productVariants'>
                {prod.variants.map((variant, index) => {
                  if (variant.title !== "Default Title"){
                    return (<button className="variantButton" key={variant.id} 
                                    onClick={() => selectVariant(index)}
                                    style={ index!==chosenVariant ? { color:'#888888', borderColor:'#888888'} : {}}
                                    >{variant.title}</button>);
                  }
                  return null;
                })}
              </div>
              <div className='productPurchase'>
                <div className='purchasePrice'>{(prod.variants[chosenVariant].price.amount * 1).toFixed(2)} {prod.variants[chosenVariant].price.currencyCode}</div>
                <button className="awesomeButton" onClick={addToCart}>Add to Cart</button>
              </div>
            </div>
          </div>
        }
      </div>
      {checkoutState.lineItems?.length > 0 && <CheckoutButton />}
    </div>
  )
}

const useFade = (initial) => {
  const [show, setShow] = useState(initial);
  const [isVisible, setVisible] = useState(show);

  // Update visibility when show changes
  useEffect(() => {
      if (show) setVisible(true);
  }, [show]);

  // When the animation finishes, set visibility to false
  const onAnimationEnd = () => {
      if (!show) setVisible(false);
  };

  const style = { animation: `${show ? "fadeIn" : "fadeOut"} .3s` };

  // These props go on the fading DOM element
  const fadeProps = {
      style,
      onAnimationEnd
  };

  return [isVisible, setShow, fadeProps];
};